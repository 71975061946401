.admin-container{
  width:100%;
  min-height:100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../../../Images/nyawson-admin-bg\ image-min.png');
}

.admin-overlay{
  background-color: rgba(0,0,0,0.5);
  width:100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  column-gap: 5%;
  min-height: 100vh;
}

.admin-edit-overlay{
  background-color: rgba(0,0,0,0.5);
  width:100%;
  display: block;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.admin-edit-overlay h3{
  font-size: 3vw;
  color: #fff;
  font-family: 'Italiana', serif;
  text-align: center;
}

.title-container{
  width: 100%;
}

.title-container h1{
  font-size: 5vw;
  color: #fff;
  font-family: 'Italiana', serif;
  text-align: center;
}

.login-container{
  width: 100%;
  min-height: 30vh;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 20px;
  align-items: center;
}

.login-container label{
  color: #fff;
  text-align: left;
  width:50%;
  font-size: 2vw;
  font-family: 'Italiana', serif;
}

.login-container input[type=text],.login-container .password-group{
  width:50%;
  height: 3vw;
  border-radius: 15px;
}

.password-group{
  display: flex;
  justify-content: center;
  align-items: center;
  row-gap: 0;
  column-gap: 0;
}

.password-group input[type=password]{
  width:90%;
  height:100%;
  border-radius: 15px 0px 0 15px;
  margin:0;
}

.password-group input[type=submit]{
  width: 10%;
  border-radius: 5px;
  width: 10%;
  height: 100%;
  background-color: black;
  color: white;
  border-radius: 0 15px 15px 0;
  margin:0;
  font-family: 'Italiana', serif;
  font-size: 1vw;
}

.password-group input[type=submit]:hover{
  background-color: rgba(0,0,0,0.5)
}