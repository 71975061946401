div.publications-edit-section{
  width: 100%;
  display: block;
  min-height: 100vh;
  overflow: hidden;
  padding: 1%;
}

div.publications-edit-section h1{
  font-family: 'Italiana',serif;
  color: #fff;
  text-align: left;
  font-size: 3vw;
}

div.publications-edit-section div.add-publication-section{
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: flex-end;
  column-gap: 30px;
  padding: 0 1%;
  align-items: center;
}

div.add-publication-section button{
  width: 15%;
  height: 3.5vw;
  background-color: rgba(255,255,255,0.5);
  color: #000;
  font-family: 'Italiana', serif;
  border-radius: 10px;
  font-size: 1.5vw;
  border: thin solid rgba(0,0,0,0)
}

div.add-publication-section button:hover{
  background-color: rgba(255,255,255,1);
}

div.publications-edit-section div.publications-container{
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
  row-gap: 10px;
  column-gap: 5px;
  overflow-x: hidden;
  overflow-y: scroll;
}

div.publications-container::-webkit-scrollbar, form.publication-container::-webkit-scrollbar{
  background-color: #fff;
  border-radius: 20px;
  height: 1px;
}

div.publications-container::-webkit-scrollbar-thumb,form.publication-container::-webkit-scrollbar-thumb{
  background-color: rgba(17,17,17,0.7);
  border-radius: 20px;
  width: 0.2vw;
}

div.publications-container section.publication{
  min-width: 30%;
  max-width: 30%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: rgba(0,0,0,0.7);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 2px 2px 2px 2px rgba(0,0,0,0.7);
}

section.publication section.close-section{
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

section.close-section button{
  background-color: rgba(0,0,0,0);
  border: thin solid rgba(0,0,0,0);
  color: #fff;
  font-family: 'Italiana',serif;
  font-size: 1.5vw;
}

section.publication section.cover-page-section{
  width:100%;
  min-height: 70vh;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

}

section.cover-page-section section.description-section{
  width:inherit;
  min-height: inherit;
  z-index: 1;
  display:flex;
  flex-direction: row;
  row-gap: 0;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 1%;
  background-color: rgba(0,0,0,0.7);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

}

section.description-section p{
  font-size: 1vw;
  font-family: 'Darker Grotesque',sans-serif;
  text-align: justify;
  color: #fff;
}

section.publication:hover{
  cursor: pointer;
}

section.close-section button:hover{
  color: red;
}

div.publications-edit-section form.publication-container{
  width: 100%;
  min-height: 80vh;
  max-height: 80vh;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow-x: hidden;
  overflow-y: scroll;
}

form.publication-container h1{ margin-top: 3%; }

form.publication-container section.publication-images-section{
  width: 100%;
  height: 85%;
  display: flex;
  justify-content: space-around;
  align-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

section.publication-images-section section.publication-section{
  width: 33%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
}

section.publication-section section.publication-form-container{
  min-width:100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: flex-start;
}

section.publication-form-container section.close-section{
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

section.publication-form-container section.publication-image-container{
  width: 100%;
  height:80%
}

section.publication-image-container img{
  max-width: 100%;
}

section.publication-form-container section.input-image-container{
  width: 100%;
  height: 20%;
}

form.publication-container div.publication-description-container, form.publication-container div.publication-title-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 5%;
  height:50%;
}

form.publication-container div.cover-photo-container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
}

div.cover-photo-container div.cover-photo-image-submit-container{
  width:50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

div.cover-photo-image-submit-container section.close-section{
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

div.cover-photo-image-submit-container section.cover-photo-image-container{
  width: 100%;
  height:80%;
  display: flex;
  justify-content: center;
}

section.cover-photo-image-container img{
  max-width:60%
}

div.cover-photo-image-submit-container section.input-image-container{
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
}

form.publication-container div.submit-button-section{
  width: 100%;
  margin-top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

form.create-publication-container{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  row-gap: 1vh;
}

form.create-publication-container label{
  font-family: 'Italiana',serif;
  font-size: 1.5vw;
}

div.delete-container{
  width: 100%;
  padding: 30%;
  display: flex;
  justify-content: space-evenly;
}

div.delete-container button.no{
  width: 40%;
  font-family: 'Italiana',sans-serif;
  font-size: 2.5;
  border-radius: 0.5vw;
  color: black;
  background-color: darkgrey;
  border: thin solid white;
}

button.no:hover{
  box-shadow: 1px 1px 1px 1px rgba(169, 169, 169, 0.7);

}
div.delete-container button.yes{
  width: 40%;
  font-family: 'Italiana',sans-serif;
  font-size: 2.5;
  background-color: rgb(145, 20, 20);
  color: #fff;
  text-align: center;
  border-radius: 0.5vw;
  border: thin solid white;
}
button.yes:hover{
  box-shadow: 1px 1px 1px 1px rgba(145, 20, 20, 0.7);
}