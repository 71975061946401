div.current-page-iframe-container{
  width:100%;
  min-height:70vh;
  padding: 3%;
}

div.current-page-iframe-container iframe{
  width:100%;
  min-height:70vh;
}

div.edit-container{
  width: 100%;
  min-height: 80vh;
  max-height: 80vh;
  display: block;
  padding: 1%;
}

div.edit-container h1{
  font-family: 'Italiana', serif;
  color: #fff;
  text-align: left
}

div.edit-container form.edit-container{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 50px;
  }

form.edit-container div.image-sumbit-container{
  min-width: 24%;
  max-width: 24%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-bottom-right-radius: 20px;
}

div.image-sumbit-container div.image-container{
  display: block;
  width: 100%;
  height: 60vh;
  overflow: hidden;
}

div.image-container img.current-or-new-image{
  max-width: 100%;
}

div.image-sumbit-container input.image-input-section{
  width: 100%;
  height: 10%;
  border-bottom-right-radius: 20px;
  color: #fff;
}