form.gallery-admin-section{
  width: 100%;
  display: block;
  min-height: 100vh;
  overflow: hidden;
  padding: 1%;
}

form.gallery-admin-section h1{
  font-family: 'Italiana',serif;
  color: #fff;
  text-align: left;
  font-size: 3vw;
}

form.gallery-admin-section div.image-display-container{
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  align-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
}

div.image-display-container section.image-column{
  width:25%;
  min-height: 90vh;
  max-height: 100%;
  margin: 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: flex-start;
  column-gap: 1vw;
}

section.image-column div.image-sumbit-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: auto !important;
  margin-bottom: 5px;
  filter: none;
  overflow: hidden;
}

div.image-sumbit-container div.delete-image-section{
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: flex-end;
}
div.delete-image-section button{
  font-size: 2vw;
  color: #fff;
  background-color: rgba(0,0,0,0);
  border: thin solid rgba(0,0,0,0);
}

div.delete-image-section button:hover{
  color: lightcoral;
}
div.image-sumbit-container div.image-container{
  width: 100%;
  height: 80%;
}

form.gallery-admin-section div.page-and-submit-button-container{
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: center;
}

div.page-and-submit-button-container section.pages-container{
  width: 50%;
  height: 100%;
  padding: 1%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0;
  overflow-x: scroll;
  overflow-y: hidden;
}

section.pages-container button.page-number{
  border-radius: 50%;
  width: 3vw;
  height: 3vw;
  background-color: rgba(232,232,232,0.5);
  /* font-family: 'Italiana',serif; */
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: 2vw;
}

section.pages-container button:hover{
  background-color: #fff;

}

section.pages-container::-webkit-scrollbar{
  background-color: #fff;
  height: 1px;
}

section.pages-container::-webkit-scrollbar-thumb{
  background-color: rgba(17,17,17,0.7);
  border-radius: 20px;
  width: 0.2vw;
}

div.page-and-submit-button-container section.button-container{
  width: 45%;
  height: 100%;
  padding: 2%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

section.button-container .admin-submit-button{
  width: 40%;
}