div.page-not-found-screen-container{
  width:100%;
  height: 100vh;
  display: block;
  justify-content: center;
  flex-direction: column;

  & div.four-o-four-container{
    width: 100%;
    height: 100%;
    background-image: url('../../../../Images/nyawson-admin-bg\ image-min.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    & div.overlay{
      background-color: rgba(0,0,0,0.5);
      width:100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      & h1{
        font-family: 'Italiana',serif;
        color: #fff
      }
    }
  }
}