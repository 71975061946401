div.modal-image-container{
  width: 100%;
  height:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;

  & section{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & section.image-container{
    min-height: 50vh;
    min-width: 90%;
    max-width: 80%;
    padding-bottom: 1%;
    height: 100%;
    & img{
      max-width: 100%;
      max-height: 100%;
    } 
  }

  & section.pointers{
    width: 5%;
    height: 100%;
    padding: 2%;

    & span{
      font-size: 5vh;
      font-weight: 100;
    }

    & span:hover{
      cursor: pointer;
    }
  }

  & section.indicator-container{
    width: 100%;
    margin: 0 0.5%;
    height: 2.5vh;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    column-gap: 0.5vh;
    overflow-x: scroll;
    overflow-y: hidden;

    & span{
      border-radius: 50%;
      border: thin solid rgba(0,0,0,0.5);
      height: 100%;
      max-width: 6%;
      min-width: 6%;
      background-color: rgba(0,0,0,0.5);
    }

    & span.selected{
      border: thin solid rgba(0,0,0,8);
      background-color: rgba(0,0,0,0.8);
    }
  }
}

.carousel-container{
  width: 100%;
  margin: 2% 0;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;

  & section.image-indicator-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;

    & section{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & section.image-container{
      width: 100%;
      min-height: 90%;

      & section.image{
        min-width: 90%;
        max-width: 100%;
        height: 100%;

        & img{
          min-width: 100%;
          max-height: 100;
        }

        & img.disappear{
          opacity: 1;
          animation-name: disappear;
          animation-duration: 1s;
          animation-timing-function: linear;
          animation-iteration-count: 1;
          animation-delay: 0s;
        }

        & img.appear{
          animation-name: appear;
          animation-duration: 1s;
          animation-timing-function: linear;
          animation-iteration-count: 1;
          animation-delay: 0s;
          animation: appear 1 linear 0 1s normal none;
        }
        
      }
      
      & section.pointers{
        width: 5%;
        height: 100%;
        padding: 2%;

      & span{
          font-size: 5vh;
          font-weight: 100;
        }
      }
    }

    & section.indicator-container{
      width: 100%;
      height: 5%;
      padding: 1.5% 0;
      justify-content: space-around;
      overflow-x: scroll;

      & span{
        border-radius: 50%;
        border: thin solid rgba(0,0,0,0.5);
        height: 100%;
        max-width: 6%;
        min-width: 6%;
        background-color: rgba(0,0,0,0.5);
      }

      & span.selected{
        border: thin solid rgba(0,0,0,8);
        background-color: rgba(0,0,0,0.8);
      }
    }
  }
}

@keyframes disappear {
  from{opacity: 1;}
  to{opacity: 0;}
}

@keyframes appear {
  from{opacity: 0;}
  to{opacity: 1;}
}

@media (min-width:3000px) {
  .modal-content{
    width:45vw;
  }

  .modal-header{
    & .btn-close{
      font-size: 3vh;
    }
  }
}