div.screen-container{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width:100%;
  height: 100vh;
  overflow: hidden;

  & div.publications-container{
    padding-top: 4%;
    width:75%;
    height: 100%;

    & .publications-title{
      font-size: 3vw;
      text-align: left;
      font-family: 'Italiana', serif;
    }

    & div.publications{
      padding: 1%;
      height:90%;
      width:100%;
      display: flex;
      align-items: flex-start;
      row-gap: 10px;
      flex-wrap: wrap;
      justify-content: space-evenly;
      overflow-y: scroll;
      overflow-x: hidden;

      & div.publications-card{
        width: 35%;
        height: 70%;
        margin: 0.5%;
        display: block;
        overflow: visible;
        text-decoration: none;
        color: #000;
        font-family: 'Darker Grotesque',sans-serif;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        box-shadow: 2px 2px 2px 2px rgba(0,0,0,0.7);

        & section.image-container{
          width:100%;
          height:100%;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          z-index: 0;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          position: relative;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }

        & section.description{
          position:relative;
          width: inherit;
          height: inherit;
          z-index: 1;
          display:flex;
          flex-direction: row;
          row-gap: 0;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          padding: 1%;
          background-color: rgba(0,0,0,0.7);
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        
          &  p{
            font-size: 1vw;
            font-family: 'Darker Grotesque',sans-serif;
            text-align: justify;
            width:100%;
            color: #fff;
          }
          
          & h1{
            color: #fff;
            font-size: 3vw;
            font-family: 'Darker Grotesque',sans-serif;
            font-weight: 200;
            text-align: center;
          }

          & button{
            width: 100%;
            text-decoration: none;
            text-align: center;
            font-size: 2vw;
            background-color: rgba(0,0,0,0);
            border: thin solid rgba(0,0,0,0);
            color: #fff
          }

          & button:hover{
            text-shadow: 1px 1px 1px #fff;
          }
        }
      }
    }
  }

  & div.publications-gallery-container{
    padding-top: 4%;
    width:75%;
    height: 100%;

    & .publications-title{
      font-size: 3vw;
      text-align: left;
      font-family: 'Italiana', serif;
    }

    & div.publications-2{
      padding: 1%;
      height:90%;
      width:100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-evenly;
      overflow-y: scroll;
      overflow-x: hidden;

      & div.publications-column{
        max-width: 33%;
        margin: 0 0.25%;
        height: 100%;

        & div.publications-2-img-container{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: auto !important;
          margin-bottom: 5px;
          filter: none;
          transition: filter 2s;
          overflow: hidden;

          & img{
            min-width:50%;
            min-height:10%;
            cursor: pointer;
          }

          & img:hover{
            filter: grayscale(50%);
          }
        }
      }
    }
  }

  & div.publications::-webkit-scrollbar,div.publications-2::-webkit-scrollbar{
    background-color: #fff;
    height: 1px;
  }
  
  & div.publications::-webkit-scrollbar-thumb,div.publications-2::-webkit-scrollbar-thumb{
    background-color: rgba(17,17,17,0.7);
    border-radius: 20px;
    width: 0.2vw;
  }
}

div.mobile-screen-container{
  width:100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & div.publications-container{
    padding-top: 4%;
    width: 100%;
    height: 100%;

    & .publications-title{
      font-size: 3vh;
      text-align: left;
      font-family: 'Italiana', serif;
    }

    & div.publications{
      padding: 1%;
      height:100%;
      width:100%;
      display: flex;
      align-items: flex-start;
      row-gap: 1vh;
      flex-wrap: wrap;
      justify-content: space-evenly;
      overflow-y: scroll;
      overflow-x: hidden;

      & div.publications-card{
        width: 95%;
        height: 70%;
        margin: 0.5%;
        display: block;
        overflow: visible;
        text-decoration: none;
        color: #000;
        font-family: 'Darker Grotesque',sans-serif;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        box-shadow: 2px 2px 2px 2px rgba(0,0,0,0.7);

        & section.image-container{
          width:100%;
          height:100%;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          z-index: 0;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          position: relative;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }

        & section.description{
          position:relative;
          width: inherit;
          height: inherit;
          z-index: 1;
          display:flex;
          flex-direction: row;
          row-gap: 0;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          padding: 1%;
          background-color: rgba(0,0,0,0.7);
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        
          &  p{
            font-size: 2vh;
            width:100%;
            font-family: 'Darker Grotesque',sans-serif;
            text-align: justify;
            color: #fff;
          }
          
          & h1{
            color: #fff;
            font-size: 3vh;
            font-family: 'Darker Grotesque',sans-serif;
            font-weight: 200;
            text-align: center;
          }

          & button{
            width: 100%;
            text-decoration: none;
            text-align: center;
            font-size: 2.5vh;
            background-color: rgba(0,0,0,0);
            border: thin solid rgba(0,0,0,0);
            color: #fff
          }
        }
      }
    }
  }

  & div.publications-gallery-container{
    padding-top: 4%;
    width:100%;
    max-height: 100%;

    & .publications-title{
      font-size: 3vh;
      text-align: left;
      font-family: 'Italiana', serif;
    }

    & div.publications{
      padding: 1%;
      height:90%;
      width:100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      align-content: flex-start;
      flex-wrap: wrap;
      flex-direction: row;
      row-gap: 1vh;
      overflow-y: scroll;
      overflow-x: hidden;

      & div.publication-row{
        width: 100%;
        margin: 0 0.25%;
        height: 20vh;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
        flex-direction: column;
        flex-wrap: wrap;
        column-gap: 1vh;
        overflow: hidden;
        overflow-x: scroll;
        overflow-y: hidden;

        & img{
          min-height:100%;
          cursor: pointer;
          animation-duration: 3s;
          animation-name: appear;
          animation-iteration-count: 1;
          animation-timing-function: linear;
        }
      }
    }
  }
}