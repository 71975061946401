div.company-footer-field{
  width:100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  bottom: 1px;
}

div.company-footer-field *{
  font-size: 2vh;
  text-decoration: none;
  font-family: 'Italiana',serif;
}