form.admin-edit-container{
  width: 100%;
  min-height: 40vh;
  max-height: 100%;
  display: block;
  padding: 1%;
}

form.admin-edit-container h1{
  font-family: 'Italiana', serif;
  color: #fff;
  text-align: left;
  margin-top: 5%;
  font-size: 3vw;
}

div.admin-about-text-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

div.admin-about-text-container > label{
  font-family: 'Italiana', serif;
  color: #fff;
  text-align: left;
  font-size: 1.5vw;
  margin-top: 2%;
}

div.admin-about-images-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

div.admin-about-images-container div.image-sumbit-container{
  min-width: 30%;
  max-width: 30%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-bottom-right-radius: 20px;
}