div.polaroid-video-edit-container{
  width: 100%;
  height: 80vh;
  padding: 0 5%;
  /* overflow: hidden; */
}

div.polaroid-video-edit-container h1{
  font-family: 'Italiana',serif;
  font-size: 3vw;
  text-align: left;
  color: #fff;
}


div.polaroid-video-edit-container form.polaroid-video-container{
  width: inherit;
  height:100%;
  overflow-y: scroll;
  padding: 0 5%;
  overflow-x: hidden;
  display: block;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

form.polaroid-video-container::-webkit-scrollbar{
  background-color: #fff;
  height: 1px;
  border-radius: 20px;
}

form.polaroid-video-container::-webkit-scrollbar-thumb{
  background-color: rgba(17,17,17,0.7);
  border-radius: 20px;
  width: 0.2vw;
}

form.polaroid-video-container h2{
  font-family: 'Italiana',serif;
  font-size: 2vw;
  margin-top: 5%;
  text-align: left;
  color: #fff;
}
form.polaroid-video-container div.polaroid-section{
  width: inherit;
  min-height: 40%;
  margin: 1%;
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

form.polaroid-video-container div.video-section{
  width: inherit;
  min-height: 40%;
  margin: 1%;
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

div.polaroid-section section.polaroid-container{
  max-width: 30%;
  display: flex;
  flex-direction: column;
}

div.video-section section.video-container{
  max-width: 45%;
  display: block;
  flex-direction: column;
}

section.polaroid-container section.close-section,section.video-container section.close-section{
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

section.polaroid-container section.image-container{
  height: 70%;
  width: 100%;
  display: flex;
  justify-content: center;
}

section.video-container section.video{
  height: 50%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

section.video-container iframe, section.video-container video{
  width: 100%;
}
section.polaroid-container section.input-section, section.video-container section.input-section{
  display: flex;
  justify-content: center;
  max-height: 15%;
  width: 100%;
}
section.image-container img{
  max-width: 100%;
}
 
div.polaroid-video-edit-container div.submit-button-section{
  width:inherit;
  height: 20%;
  display: flex;
  justify-content: space-evenly
}


div.checkbox-container{
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

div.checkbox-container section{
  display: flex;
  flex-direction: column;
}

div.checkbox-container  label{
  font-family: 'Italiana',serif;
  font-size: 1vw;
}

form.post-polaroid-container{
  width:100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

form.post-polaroid-container label{
  font-family: 'Italiana',serif;
  font-size: 1vw;
  margin-top: 5%;
}