.admin-home-overlay{
  background-color: rgba(0,0,0,0.5);
  width:100%;
  display: block;
  min-height: 100vh;
  overflow: hidden;
}

.admin-home-title-container{
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3%;
}

.admin-home-title-container h3{
  font-family: 'Italiana', serif;
  color: #fff;
  font-size: 2vw;
}

.admin-home-title-container button, .admin-submit-button{
  width: 15%;
  height: 3.5vw;
  background-color: rgba(255,255,255,0.5);
  color: #000;
  font-family: 'Italiana', serif;
  border-radius: 10px;
  font-size: 1.5vw;
  border: thin solid rgba(0,0,0,0)
}

.admin-home-title-container button:hover, .admin-submit-button:hover{
  background-color: rgba(232,232,232,1);
}

.admin-home-row{
  width:100%;
  min-height: 33vh;
  max-height: 33%;
  padding-top: 1%;
  display: flex;
  justify-content: space-evenly;
  align-items: center ;
}

.admin-home-row a.admin-home-tile{
  width: 30%;
  min-height: 33vh;
  max-height: 100%;
  color: #fff;
  font-family: 'Italiana', serif;
  font-size: 2.5vw;
  text-align: center;
  text-decoration: none;
}

a.admin-home-tile img{
  width:100%;
  min-height: 33vh;
  max-height: 100%;
}

a.admin-home-tile img:hover{
  filter: grayscale(50%);
}

.admin-home-title-container a.admin-back-button{
  font-size: 4vw;
  color: #dfdbdb;
  text-decoration: none;
}

a.admin-back-button:hover{
  color: #fff;
}