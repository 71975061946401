/* -------------------First Desktop Nav CSS Rules----------------*/
div.nav-bar{
  width:100%;
  display:flex;
  height:12vh;
  justify-content: center;
  align-items: center;
}

div.nav-bar div.nav-bar-divider-left,div.nav-bar div.nav-bar-divider-right{
  width:50%;
  margin:0;
  justify-content: center;
  align-items: center;
}

div.nav-bar div.nav-bar-divider-left{
  justify-content: flex-start;
  display:flex;
  padding-top: 3%;
}

div.nav-bar div.nav-bar-divider-right{
  justify-content: flex-end;
  animation-name: appear;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  display: flex;
  padding: 3% 2% 0 0;

}

div.nav-bar-divider-left a.logo{
  font-family: 'Italiana', serif;
  font-size: 4vw;
  font-weight: 500;
  text-decoration: none;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  color: #000000;
}

div.nav-bar-divider-right a.nav-item-1{
  font-family: 'Darker Grotesque', sans-serif;
  font-size: 2vw;
  text-decoration: none;
  color: #000000;
  margin-right: 7%;
  animation-timing-function: linear;
  animation-duration: 1s;
  animation-iteration-count: 1;
  opacity: 0;
}

a.nav-item-1 img.logo,a.nav-items img.logo{
  width: 4vw;
  height: 4vw;
  padding-right: 10%;
}

/* -------------------Second Desktop Nav CSS Rules----------------*/

div.second-nav-bar{
  width:25%;
  height:100%;
  padding: 0 5%;
  min-height:100vh;
  display: none;
  flex-wrap: wrap;
  column-gap: 2;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}

div.second-nav-bar div.nav-item-2{
  width:100%;
  margin-top: 7.5%;
  height: 5vw;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  animation-timing-function: linear;
  animation-duration: 1s;
  animation-iteration-count: 1;
}

div.nav-item-2 a.nav-link{
  font-family: 'Italiana', serif;
  font-size: 2.5vw;
  display:flex;
  align-items: center;
  justify-content: flex-start;
  width:100%;
  text-decoration: none;
  color: #000000;
}

a.nav-link img.logo{
  width: 3vw;
  height: 3vw;
}

div.second-nav-bar  a.logo{
  width:100%;
  font-family: 'Italiana', serif;
  text-align: left;
  font-size: 3.5vw;
  font-weight: 500;
  text-decoration: none;
  text-align: center;
  animation-name: appear;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  color: #000000;
}

/* -------------------Mobile Nav CSS Rules----------------*/

div.mobile-nav-bar{
  width:100%;
  height:10vh;
  display:flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20%;
  padding: 0 2%;
  background-color: #171717;
}

div.mobile-nav-bar .logo{
  font-size: 4vh;
  font-family: 'Italiana', sans-serif;
  color: #ffffff;
  text-decoration: none;
}

div.mobile-nav-bar div.icon-container{
  cursor: pointer;
  width:4vh;
  height:100%;
  padding-top: 4%
}

div.icon-container .bar1, div.icon-container .bar2 ,div.icon-container .bar3{
  width: 100%;
  height: 5px;
  background-color: #ffffff;
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  transform: translate(0, 11px) rotate(-45deg);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
  transform: translate(0, -11px) rotate(45deg);
}

div.mobile-nav-menu{
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  width:0%;
  height: 100%;
  z-index:5;
  position: absolute;
  float: right;
  display:flex;
  align-content: center;
  min-height: 100vh;
  background-color: rgba(32,32,32,0.9);
}

div.mobile-nav-menu div.nav-item-3{
  width:100%;
  margin-top: 50%;
  height: 5vw;
  align-items: center;
  justify-content: center;
  display: flex;
  opacity: 0;
  animation-timing-function: linear;
  animation-duration: 1s;
  animation-iteration-count: 1;
}

div.nav-item-3 a.nav-link{
  font-family: 'Italiana', serif;
  font-size: 3vh;
  display:flex;
  align-items: center;
  justify-content: center;
  width:100%;
  text-decoration: none;
  color: #fff;
}


/* ------------------------------------Responsive CSS Rules---------------------------------------- */

@media (max-width:600px) and (min-width:500px) {
  div.nav-bar-divider-left a.logo{
    font-size: 5vw;
  }

  div.nav-bar-divider-right a.nav-items{
    font-size: 16px;
  }

  div.nav-bar-divider-right img.logo{
    width:5vw;
    height:5vw;
  }

  div.nav-link img.logo{
    width:5vw;
    height:5vw;
  }
}

@keyframes appear{
  from{opacity: 0;}
  to{opacity: 1;}
}

@keyframes close {
  from{width:40%;}
  to{width:0%;}
}

@keyframes open {
  from{width:0%;}
  to{width:40%;}
}