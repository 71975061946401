.hightlights-photos-container{
  height: 70vh;
  padding: 0.1%;
  position: absolute;
  max-height: 100%;
  min-width: 100%;
  margin-top: 5%;
  display:flex;
  justify-content: space-evenly;
  flex-wrap:nowrap;
  align-items: center;
  overflow:hidden
}

.hightlights-photos-container::-webkit-scrollbar{
  background-color: #fff;
  height: 1vw;
}

.hightlights-photos-container::-webkit-scrollbar-thumb{
  background-color: rgba(17,17,17,0.7);
  border-radius: 20px;
  width: 1px;
}

.hightlights-photos-container .hightlights-photo-container{
  min-width: 25%;
  max-width: 30%;
  height:100%;
  margin: 0 0.3%;
  display: none;
  align-items: center;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-name: appear-image;
}

.hightlights-photo-container img{
  max-width:100%;
  min-height:100%
}


.mobile-hightlights-photos-container{
  height:70vh;
  padding: 0.1%;
  min-width: 100%;
  margin-top: 15%;
  justify-content: flex-start;
  display:flex;
  column-gap: 5vw;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
}

.mobile-hightlights-photos-container .hightlights-photo-container{
  min-width: 100%; 
  height:100%;
  margin: 0 0.3%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-name: appear-image;
}

.hightlights-photo-container img{
  width:100%;
  min-height:100%;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-name: appear-image;
}

@keyframes appear-image {
  from{opacity: 0;}
  to{opacity: 1;}
}
