div.screen-container{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width:100%;
  height: 100vh;
  overflow: hidden;

  & div.gallery-container{
    padding-top: 4%;
    width:75%;
    height: 100%;

    & .gallery-title{
      font-size: 3vw;
      text-align: left;
      font-family: 'Italiana', serif;
    }

    & div.gallery{
      padding: 1%;
      height:85%;
      width:100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-evenly;
      overflow-y: scroll;
      overflow-x: hidden;

      & div.gallery-column{
        max-width: 25%;
        margin: 0 0.25%;
        height: 100%;

        & div.gallery-img-container{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: auto !important;
          margin-bottom: 5px;
          filter: none;
          transition: filter 2s;
          overflow: hidden;

          & img{
            min-width:50%;
            min-height:10%;
            cursor: pointer;
          }

        & img:hover{
          filter: grayscale(50%);
          }
        }
      }
    }

    & div.gallery::-webkit-scrollbar{
      background-color: #fff;
      height: 1px;
    }
    
    & div.gallery::-webkit-scrollbar-thumb{
      background-color: rgba(17,17,17,0.7);
      border-radius: 20px;
      width: 0.2vw;
    }

    & div.loading-section{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height:15%;

      & *{
        font-size: 2vw;
      }
    }
  }
}


div.mobile-screen-container{
  width:100%;
  min-height: 100vh;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & div.gallery-container{
    padding-top: 4%;
    width:100%;
    height: 100%;

    & .gallery-title{
      font-size: 3vh;
      text-align: left;
      font-family: 'Italiana', serif;
    }

    & div.gallery{
      padding: 1%;
      max-height:82vh;
      width:100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      align-content: flex-start;
      flex-wrap: wrap;
      flex-direction: row;
      row-gap: 1vh;
      overflow-y: scroll;
      overflow-x: hidden;

      & div.gallery-row{
        width: 100%;
        margin: 0 0.25%;
        height: 20vh;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
        flex-direction: column;
        flex-wrap: wrap;
        column-gap: 1vh;
        overflow: hidden;
        overflow-x: scroll;
        overflow-y: hidden;

        & img{
          min-height:100%;
          cursor: pointer;
          animation-duration: 3s;
          animation-name: appear;
          animation-iteration-count: 1;
          animation-timing-function: linear;
        }
      }
      
      & div.gallery-row::-webkit-scrollbar{
        background-color: #fff;
        height: 1px;
      }
      
      & div.gallery-row::-webkit-scrollbar-thumb{
        background-color: rgba(17,17,17,0.7);
        border-radius: 20px;
        width: 0.2vw;
      }
    }

    & div.gallery::-webkit-scrollbar{
      display: none;
    }
  

    & div.loading-section{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height:10%;

      & *{
        font-size: 2vw;
      }
    }
  }

  @keyframes appear {
    from {opacity: 0};
    to {opacity: 1};
  }
}
