div.screen-container{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width:100%;
  min-height: 100vh;
  max-height: 100%;
  overflow: hidden;
}
div.polaroids-container{
  padding-top: 4%;
  width:75%;
  height: 100%;
  overflow-y: scroll;
}

div.polaroids-container div.polaroids{
  padding: 1%;
  height:50%;
  width:100%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  overflow-x: hidden;
}
div.polaroids-container .polaroids-title{
  font-size: 3vw;
  text-align: left;
  font-family: 'Italiana', serif;
}

div.polaroids::-webkit-scrollbar{
  background-color: #fff;
  height: 1px;
}

div.polaroids::-webkit-scrollbar-thumb{
  background-color: rgba(17,17,17,0.7);
  border-radius: 20px;
  width: 0.2vw;
}

div.polaroids div.polaroids-img-container{
  display: flex; 
  align-items: center;
  justify-content: center;
  width: 23%;
  margin: 0.5%;
  filter: none;
  transition: filter 2s;
  overflow: hidden;
}

div.polaroids-img-container img{
  min-width:50%;
  min-height:50%;
}

div.polaroids-img-container img:hover{
  filter: grayscale(50%);
  cursor: pointer;
}

div.polaroids div.loading-section{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height:10%;
}
div.polaroids-container div.polaroid-videos{
  padding: 1%;
  min-height:50%;
  width:100%;
  display: block;
  margin-top: 1%;
}
div.polaroids-container div.polaroid-video-container{
  padding: 1%;
  max-height:100%;
  min-height: 30vh;
  margin-top: 1%;
  max-width:100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
div.polaroid-video-container iframe,div.polaroid-video-container video{
  width:70%;
  height: 50vh;
}
div.loading-section *{
  font-size: 2vw;
}

div.mobile-screen-container{
  width:100%;
  min-height: 100vh;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & div.mobile-polaroids-container{
    padding: 4%;
    width:100%;
    min-height: 100vh;
    overflow-y: scroll;

    & .polaroids-title{
      font-size: 3vh;
      text-align: left;
      font-family: 'Italiana', serif;
    }

    & .polaroids-videos{
      padding: 1%;
      width: 100%;
      /* min-height: 100vh; */
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      flex-direction: row;
      row-gap: 1vh;
      overflow-y: scroll;
      overflow-x: hidden;

      & .polaroid{
        max-width: 45%;
        height: auto;

        & img{
          max-width: 100%;
        }
      }

      & .polaroid-video{
        max-width: 50%;
        height: auto;

        & video,iframe{
          max-width: 100%;
        }

        & video{
          height: 90%
        }
      }
    }
  }
}