div.about-sections-container{
  width: 100%;
  min-height:70vh;
  padding:0 5% 0 20%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top:5%;
  height: 100%;

  & section.about-section{
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    padding: 2%;
  
    & section.text-section{
      width:50%;
      height:100%;
      padding:1% 1% 1% 7.5%;
      overflow-y: scroll;
      overflow-x: hidden;

      & div,ul{
        width:100%;
        margin-top: 10%;

        & li{
          text-align: justify;
          font-size: 1.5vw;
          font-family: 'JetBrains Mono', monospace;
          list-style-type: none;
        }
      }

      & div:nth-child(2), div:nth-child(3){
        margin-top: 10%;
      }

      & div p{
        text-align: justify;
        font-size: 1.5vw;
        font-family: 'JetBrains Mono', monospace;
      }
    }

    & section.text-section::-webkit-scrollbar{
      background-color: #fff;
      height: 1px;
    }

    & section.text-section::-webkit-scrollbar-thumb{
      background-color: rgba(17,17,17,0.7);
      border-radius: 20px;
      width: 1px;
    }

    & section.img-section{
      width:50%;
      display: flex;
      justify-content: center;
      align-items: center;
      height:100%;

      & img{
        height: 100%;
        max-width: 100%
      }
    }
  }

  & .about-me-title{
    font-size: 3vw;
    text-align: left;
    margin-top: 5%;
    font-family: 'JetBrains Mono', monospace;
  }

  & .title{
    font-size: 2vw;
    text-align: left;
    font-family: 'JetBrains Mono', monospace;
  }
  
  & .contact-title{
    font-size: 3vw;
    margin-top: 10%;
    font-family: 'JetBrains Mono', monospace;
    text-align: center;
  }
  
  & .contact-section{
    width: 100%;
    height: 10vh;
    padding-left: 10%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    & a{
      width: 20%;
      height: 100%;
      text-decoration: none;

      & img{
        max-width:100%;
        max-height: 100%
      }
    }
  }
}

div.mobile-about-sections-container{
  width: 100%;
  min-height:70vh;
  padding: 5%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top:5%;
  height: 100%;
  overflow: hidden;

  & section.about-section{
    width: 100%;
    min-height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 2%;

    & section.text-section{
      width:100%;
      height:100%;
      padding:1%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      overflow-y: scroll;
      overflow-x: hidden;

      & div,ul{
        width:100%;
        margin-top: 10%;

        & li{
          text-align: justify;
          font-size: 2vh;
          font-family: 'JetBrains Mono', monospace;
          list-style-type: none;
        }
      }

      & div p{
          text-align: justify;
          font-size: 2vh;
          font-family: 'JetBrains Mono', monospace;
        }

      & div:nth-child(2), div:nth-child(3){
        margin-top: 10%;
      }
    }

    & section.img-section{
      width:100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height:100%;

      & img{
        height: 100%;
        max-width: 100%
      }
    }
  }

  & .about-me-title{
    font-size: 4vh;
    text-align: center;
    margin-top: 5%;
    font-family: 'JetBrains Mono', monospace;
  }

  & .title{
    font-size: 3vh;
    text-align: center;
    margin-top: 5%;
    font-family: 'JetBrains Mono', monospace;
  }

  & .contact-title{
    font-size: 3vh;
    margin-top: 10%;
    font-family: 'JetBrains Mono', monospace;
    text-align: center;
  }

  & .contact-section{
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: space-around;
    align-items: center;

    & a{
      width: 20%;
      height: 100%;
      text-decoration: none;

      & img{
        max-width:100%;
        max-height: 100%
      }
    }
  }
}